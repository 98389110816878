import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(38.000000, 28.000000)">
        <path
          d="M 20.865 45.5 L 6.24 21.385 L 20.865 0 L 27.43 0 L 12.675 21.385 L 27.43 45.5 L 20.865 45.5 Z M 0 45.5 L 0 0 L 5.915 0 L 5.915 45.5 L 0 45.5 Z"
          fill="#64FFDA"
        />
      </g>
      <path
        stroke="#64FFDA"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
