import React from 'react';

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g id="Logo" transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          transform="translate(25.000000, 20.000000)"
          d="M 21.709 0 L 30.405 0 L 15.425 24.058 L 31.675 46.782 L 22.852 46.782 L 7.744 24.565 L 7.744 46.782 L 0 46.782 L 0 0 L 7.744 0 L 7.744 23.423 L 21.709 0 Z"
          fill="#64FFDA"
        />
        <polygon
          id="Shape"
          stroke="#64FFDA"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
